import {takeEvery, put, select} from "redux-saga/effects"
import actions from "./actions"
import Asana from "asana";
import {ReducerType} from "../reduxType";

const asanaClient = (state: ReducerType) => state.settings.asanaClient


export function* GET_USER_DETAILS(action: {payload: string, type: string}){
    try {
        const client = yield Asana.Client.create().useAccessToken(action.payload);
        const response = yield client.users.me()
        if (response) {
            yield put({
                type: actions.SET_USER_DETAILS,
                payload: response
            })
        }
    }
    catch (e) {
    }
}
export function* GET_WORKSPACE_USERS(action: {payload: any, type: string}){
    try {
        const client = yield select(asanaClient)
        const workspaceUsers = yield client.users.findByWorkspace(action.payload)
        if (workspaceUsers.data)
        yield put({
            type: actions.SET_WORKSPACE_USERS,
            payload: workspaceUsers.data
        })
    } catch (e) {
        console.log({e})
    }
}

export default function* rootSaga(){
    yield takeEvery(actions.GET_USER_DETAILS, GET_USER_DETAILS)
    yield takeEvery(actions.GET_WORKSPACE_USERS, GET_WORKSPACE_USERS)
}