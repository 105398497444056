const actions = {
    GET_TASKS: 'tasks/GET_TASKS',
    SET_TASKS: 'tasks/SET_TASKS',

    GET_FILTERS: 'tasks/GET_FILTERS',
    SET_FILTERS: 'tasks/SET_FILTERS',

    GET_LOADING: 'tasks/GET_LOADING',
    SET_LOADING: 'tasks/SET_LOADING',

    GET_CUSTOM_COLUMNS: 'tasks/GET_CUSTOM_COLUMNS',
    SET_CUSTOM_COLUMNS: 'tasks/SET_CUSTOM_COLUMNS',

    SET_INITIAL_FILTERS: 'TASKS/SET_INITIAL_FILTERS'

}

export default actions