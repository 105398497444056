import { all } from "redux-saga/effects"
import workspaces from "./workspaces/sagas"
import projects from "./projects/sagas"
import tasks from "./tasks/sagas"
import user from "./user/sagas"

export default function* rootSaga(){
    yield all([
        workspaces(),
        projects(),
        tasks(),
        user()
        ])
}