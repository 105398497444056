import actions from "./actions"

const initialState = {
    token: null,
    asanaClient: null
}

export default function settingsReducer(state = initialState, action: {payload: any, type: string}):any{
    switch (action.type) {
        case actions.SET_TOKEN:
            return {...state, token: action.payload}
        case actions.SET_ASANA_CLIENT:
            return {...state, asanaClient: action.payload}
        default:
            return state
    }
}