import actions from "./actions"

const initialState = {
    tasks: null,
    loading: false,
    filters: {
        period: null,
        user: null,
        customFields: null,
        sortBy: "due_on"
    },
    customColumns: null
}

export default function tasksReducer(state = initialState, action: {payload: any, type: string}): any{
    switch (action.type) {
        case actions.SET_TASKS:
            return {...state, tasks: action.payload};
        case actions.SET_FILTERS:
            return {...state,
                filters: {
                    ...state.filters,
                    // @ts-ignore
                    [Object.keys(action.payload)]: Object.values(action.payload)[0]}
                }
        case actions.SET_LOADING:
            return {...state, loading: action.payload}
        case actions.SET_CUSTOM_COLUMNS:
            return {...state, customColumns: action.payload}
        case actions.SET_INITIAL_FILTERS:
            return {...state, filters: initialState.filters}
        default:
            return state
    }
}