import actions from "./actions"

const initialState = {
    user: null,
    workspaceUsers: null
}

export default function userReducers(state = initialState, action: {payload: any, type: any}){
    switch (action.type){
        case actions.SET_USER_DETAILS:
            return {...state, user: action.payload}
        case actions.SET_WORKSPACE_USERS:
            return {...state, workspaceUsers: action.payload}
        default:
            return state
    }
}