import React, {useEffect} from "react"
import {Typography} from "antd";
import {Link} from "react-router-dom";
import {ROUTE_GEN_REPORTS} from "../../../config/routes";
import {withRouter} from "react-router-dom"
import {history} from "../../../index";

const Page404 = () => {

    useEffect(() => {
        history.push(ROUTE_GEN_REPORTS)
    })

    const { Title, Paragraph } = Typography;
    return <div style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }}>
        <Title level={4}>Sorry, this page isn't available</Title>
        <Paragraph>The link seems to be broken or is removed. Please visit <Link to={'/gen-reports'}>report generator</Link></Paragraph>
    </div>
}

export default withRouter(Page404)