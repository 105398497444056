import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import { History } from 'history'
import user from "./user/reducers"
import workspaces from "./workspaces/reducers"
import projects from "./projects/reducers"
import settings from "./settings/reducers"
import tasks from "./tasks/reducers"


export default (history: History) => combineReducers({
    router: connectRouter(history),
    workspaces,
    projects,
    settings,
    user,
    tasks
});