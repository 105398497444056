import { put, takeLatest } from "redux-saga/effects"
import actions from "./actions"
import settingsActions from "../settings/actions"
import Asana from "asana"

export function* GET_WORKSPACES(action: {payload: string, type: string}){
    try {
        const client = yield Asana.Client.create().useAccessToken(action.payload);
        yield put({
            type: settingsActions.SET_ASANA_CLIENT,
            payload: client
        })
        yield put({
            type: settingsActions.SET_TOKEN,
            payload: action.payload
        })
        const workspaces = yield client.workspaces.findAll()
        if (workspaces.data) {
            yield put({
                type: actions.SET_WORKSPACES,
                payload: workspaces.data
            })
        }
    } catch (e) {
        console.log({
            error: e
        })
    }
}

export default function* rootSaga(){
    yield takeLatest(actions.GET_WORKSPACES, GET_WORKSPACES)
}