import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';

import genReports from "./translation/pl/gen-reports.json";

const resources = {
    pl: {
        genReports
    }
}

const options = {
    order: ['localStorage', 'navigator', 'htmlTag'],
    lookupLocalStorage: 'language',
    caches: ['localStorage'],
    htmlTag: document.documentElement
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: options,
        resources,
        lng: "pl",
        fallbackLng: "pl",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n