import React from "react"
import styles from "./index.module.scss"
import {fab, faFacebookF} from "@fortawesome/free-brands-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const Footer = () => {

    const apps = [
        {
            url: 'https://www.facebook.com/WEBimpuls/',
            icon: 'faFacebookF',
        },
        {
            url: 'https://www.youtube.com/user/AgencjaWEBimpuls',
            icon: 'faYoutube',
        },
    ]

    const renderApps = () => {
        return apps.map((item: {url: string, icon: string}, index: number) => {
            return <a href={item.url} title={item.url}>
                <div key={index} className={styles.singleAppContainer}>
                    {
                        // @ts-ignore
                        <FontAwesomeIcon className={styles.icon} icon={fab[`${item.icon}`]} />
                    }
                </div>
            </a>

        })
    }

    return <div className={styles.footerContainer}>
            <div style={{width: 400, margin: '0 auto 0 auto', textAlign: 'center'}}>
                <div className={styles.appsContainer}>
                    {renderApps()}
                </div>
            </div>
            <div className={styles.logoContainer}>
                <p style={{fontSize: '1rem', margin: 0}}>© Wszelkie prawa zastrzeżone</p>
                <a href={"https://webimpuls.pl"} target={"_blank"} title={"WEBimpuls"}>
                    Projekt i realizacja
                    <span className={styles.logoSpan}> WEBimpuls.pl</span>
                </a>
            </div>
        </div>
}

export default Footer