import { takeLatest, select, put } from "redux-saga/effects"
import actions from "./actions"
import {ReducerType} from "../reduxType";
import usersAction from "../user/actions"

const asanaClient = (state: ReducerType) => state.settings.asanaClient

export function* GET_PROJECTS(action: {payload: any, type: string}){
    try {
        const client = yield select(asanaClient)
        const projects = yield client.projects.findByWorkspace(action.payload)
        const collectionOfProjects = yield projects.fetch(9999)

        yield put({
            type: usersAction.GET_WORKSPACE_USERS,
            payload: action.payload
        })
        if(projects.data) {
            yield put({
                type: actions.SET_PROJECTS,
                payload: collectionOfProjects
            })
        }
    } catch (e) {
        console.log({
            error: e
        })
    }
}

export default function* rootSaga(){
    yield takeLatest(actions.GET_PROJECTS, GET_PROJECTS)
}