import { put, select, takeEvery } from "redux-saga/effects"
import {ReducerType} from "../reduxType";
import actions from "./actions"

const asanaClient = (state: ReducerType) => state.settings.asanaClient;
// const periodTime = (state: ReducerType) => state.tasks.filters.period;
// const sortBy = (state: ReducerType) => state.tasks.filters.sortBy

function* GET_TASKS(action: {payload: string, type: string}){
    try {
        const client = yield select(asanaClient)
        // const completed_since = yield select(periodTime)
        // const sortByString = yield select(sortBy)
        let dependencies: any = {
            project: action.payload,
            limit: 100,
            opt_fields: 'name, completed, created_at, due_on, completed_at, assignee, assignee.name, custom_fields',
        }
        // if (completed_since?.length > 2 && sortByString === "completed_time") dependencies = {
        //     ...dependencies,
        //     completed_since
        // }
        const tasks = yield client.tasks.findAll({
            ...dependencies,
        })
        const collectionOfTasks = yield tasks.fetch(9999)
        if (collectionOfTasks) {
            yield put({
                type: actions.SET_TASKS,
                payload: collectionOfTasks
            })
            yield put({
                type: actions.SET_CUSTOM_COLUMNS,
                payload: collectionOfTasks[0].custom_fields
            })
        }
    } catch (e) {
        console.log(e)
    }
}

function* GET_FILTERS(action: {payload: any, type: string}){
    try {
        yield put({
            type: actions.SET_FILTERS,
            payload: action.payload
        })
    }
    catch (e) {
        console.log({
            error: e
        })
    }
}

function* GET_CUSTOM_COLUMNS(action: {payload: any, type: string}) {
    try {
        yield put({
            type: actions.SET_CUSTOM_COLUMNS,
            payload: action.payload
        })
    } catch (e) {
        console.log({error:e})
    }
}

export default function* rootSaga(){
    yield takeEvery(actions.GET_TASKS, GET_TASKS)
    yield takeEvery(actions.GET_FILTERS, GET_FILTERS)
    yield takeEvery(actions.GET_CUSTOM_COLUMNS, GET_CUSTOM_COLUMNS)
}