import actions from "./actions"

const initialState = {
    projects: null,
    projectGid: null
}

export default function projectsReducer(state = initialState, action: {payload: any, type: string}): any{
    switch (action.type) {
        case actions.SET_PROJECTS:
            return {...state, projects: action.payload}
        case actions.SET_PROJECT_GID:
            return {...state, projectGid: action.payload}
        default:
            return state;
    }
}