import React from 'react';
import { Route } from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import { ReactRouterPropsType } from "./routerPropsType";
import Loadable from 'react-loadable';
import "bulma";
// @ts-ignore
import Switch from 'react-router-transition-switch';

import {history} from "./index";

// ROUTES
import {
    ROUTE_GEN_REPORTS,
    ROUTE_DASHBOARD
} from "./config/routes"
import Footer from "./components/layout/footer"
import Page404 from "./pages/system/404";

// @ts-ignore
const loadable = loader => {
    return Loadable({
        loader,
        delay: false,
        loading: () => null,
    });
};


const routes = [{
        path: ROUTE_DASHBOARD,
        // Component: React.lazy(() => import("./pages/dashboard")),
        Component: loadable(() => import("./pages/dashboard")),
        exact: true
    },
    {
        path: ROUTE_GEN_REPORTS,
        // Component: React.lazy(() => import("./pages/genReports")),
        Component: loadable(() => import("./pages/genReports")),
        exact: true
    }
]

class Router extends React.Component<ReactRouterPropsType, any>{
    render(): any {
        return (
            <div style={{position: "relative", minHeight: '100vh'}}>
                <ConnectedRouter history={history}>
                    <div style={{paddingBottom: '20rem'}}>
                        <Switch>
                                {routes.map(({path, Component, exact}) => (
                                        <Route path={path} key={path} exact={exact}>
                                            {/*<Suspense fallback={null}>*/}
                                            {/*    {*/}
                                            <Component />
                                            {/*}*/}
                                            {/*</Suspense>*/}
                                        </Route>
                                    )
                                )}
                            <Route component={Page404} exact={true} />
                        </Switch>
                    </div>
                    <Route path={'/'}>
                        <Footer />
                    </Route>
                </ConnectedRouter>
             </div>
        )
    }
}

export default Router;